import React from "react";
import ReactDOM from "react-dom";

import { initApplicationsVars } from "@oprosso-frontend/main/application-vars";

import loadable from "@loadable/component";
// ci test #1
initApplicationsVars()
  .then(() => loadable.lib(() => import("app")).load())
  .then((lib) => {
    const App = lib["App"];
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root"),
    );
    return true;
  })
  .catch(console.error);
