export function getVarsFromEnv() {
  const {
    OPROSSO_API_BASE_URL,
    OPROSSO_CHATRA,
    OPROSSO_PANEL_URL,
    OPROSSO_CAN_CREATE_TRIGGER_SURVEY,
    OPROSSO_HELP_LINK,
    OPROSSO_SERVICE_LINK,
    OPROSSO_SERVICE_NAME,
    OPROSSO_SHOW_API_DOCUMENTATION,
    OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS,
    OPROSSO_CHROME_EXTENSION_ID,
    OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING,
    OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT,
    OPROSSO_SHOW_NEW_CHAT_ONLY_FOR,
    OPROSSO_SHOW_VARIABLE_IN_Q_TITLE,
    OPROSSO_SHOW_CHANNEL_LINK_EDITING,
    OPROSSO_SHOW_URL_SHORTENER,
    OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL,
    OPROSSO_SHOW_SPSS_EXPORT,
    PROD,
    OPROSSO_SUPPORT_EMAIL,
    OPROSSO_SUPPORT_CHAT_BOT,
  } = import.meta.env;

  return {
    OPROSSO_API_BASE_URL,
    OPROSSO_CAN_CREATE_TRIGGER_SURVEY,
    OPROSSO_CHATRA,
    OPROSSO_CHROME_EXTENSION_ID,
    OPROSSO_HELP_LINK,
    OPROSSO_PANEL_URL,
    OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL,
    OPROSSO_SERVICE_LINK,
    OPROSSO_SERVICE_NAME,
    OPROSSO_SHOW_API_DOCUMENTATION,
    OPROSSO_SHOW_CHANNEL_LINK_EDITING,
    OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING,
    OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT,
    OPROSSO_SHOW_NEW_CHAT_ONLY_FOR,
    OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS,
    OPROSSO_SHOW_SPSS_EXPORT,
    OPROSSO_SHOW_URL_SHORTENER,
    OPROSSO_SHOW_VARIABLE_IN_Q_TITLE,
    OPROSSO_SUPPORT_CHAT_BOT,
    OPROSSO_SUPPORT_EMAIL,
    PROD,
  } as const;
}
