/* eslint-disable no-restricted-syntax */
import { TypeOf, z } from "zod";

import Vars from "./applicationVars.json";
import { getVarsFromEnv } from "./getVarsFromEnv";

const ApplicationVars = z.object({
  apiUrl: z.string(),
  canCreateTriggerSurvey: z.boolean(),
  chatra: z.string().optional(),
  chromeExtensionId: z.string(),
  helpLink: z.string().url(),
  panelUrl: z.string().startsWith("/").endsWith("/").min(3, {
    message: `Because first letter eq '/', last letter eq '/' and pathname need at least one letter.`,
  }),
  preScrollOpenedQuestionInPanel: z.boolean(),
  prod: z.boolean(),
  serviceLink: z.string(),
  serviceName: z.string(),
  showApiDocumentation: z.boolean(),
  showChannelLinkEditing: z.boolean(),
  showImportByListButtonInMailing: z.boolean(),
  showNewChatByDefault: z.boolean().optional(),
  showNewChatOnlyFor: z.string().array().optional(),
  showReactionInAllSurveys: z.boolean(),
  showSPSSExport: z.boolean(),
  showUrlShortener: z.boolean(),
  showVariableInQTitle: z.boolean(),
  supportChatBot: z.string(),
  supportEmail: z.string(),
});

const {
  OPROSSO_API_BASE_URL,
  OPROSSO_CAN_CREATE_TRIGGER_SURVEY,
  OPROSSO_CHATRA,
  OPROSSO_CHROME_EXTENSION_ID,
  OPROSSO_HELP_LINK,
  OPROSSO_PANEL_URL,
  OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL,
  OPROSSO_SERVICE_LINK,
  OPROSSO_SERVICE_NAME,
  OPROSSO_SHOW_API_DOCUMENTATION,
  OPROSSO_SHOW_CHANNEL_LINK_EDITING,
  OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING,
  OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT,
  OPROSSO_SHOW_NEW_CHAT_ONLY_FOR,
  OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS,
  OPROSSO_SHOW_SPSS_EXPORT,
  OPROSSO_SHOW_URL_SHORTENER,
  OPROSSO_SHOW_VARIABLE_IN_Q_TITLE,
  OPROSSO_SUPPORT_EMAIL,
  OPROSSO_SUPPORT_CHAT_BOT,
  PROD,
} = getVarsFromEnv();

const isFallback = (name?: string): boolean => (name ? name.startsWith("$") : true);
const toBoolean = (value?: string | boolean) => {
  if (!value) {
    return false;
  }

  return typeof value === "string" ? value === "true" : value;
};
const toArray = (value?: string, separator = ",") => {
  if (!value) return;

  return value
    .split(separator)
    .map((el) => el.trim())
    .filter((el) => el.length);
};

let showNewChatByDefault = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT)
    ? OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT
    : Vars.OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT,
);
let showNewChatOnlyFor = toArray(
  isFallback(Vars.OPROSSO_SHOW_NEW_CHAT_ONLY_FOR)
    ? OPROSSO_SHOW_NEW_CHAT_ONLY_FOR
    : Vars.OPROSSO_SHOW_NEW_CHAT_ONLY_FOR,
);

let apiUrl = isFallback(Vars.OPROSSO_API_BASE_URL) ? OPROSSO_API_BASE_URL : Vars.OPROSSO_API_BASE_URL;
let chatra = isFallback(Vars.OPROSSO_CHATRA) ? OPROSSO_CHATRA : Vars.OPROSSO_CHATRA;
let helpLink = isFallback(Vars.OPROSSO_HELP_LINK) ? OPROSSO_HELP_LINK : Vars.OPROSSO_HELP_LINK;
let panelUrl = isFallback(Vars.OPROSSO_PANEL_URL) ? OPROSSO_PANEL_URL : Vars.OPROSSO_PANEL_URL;

let canCreateTriggerSurvey = toBoolean(
  isFallback(Vars.OPROSSO_CAN_CREATE_TRIGGER_SURVEY)
    ? OPROSSO_CAN_CREATE_TRIGGER_SURVEY
    : Vars.OPROSSO_CAN_CREATE_TRIGGER_SURVEY,
);

let serviceName = isFallback(Vars.OPROSSO_SERVICE_NAME) ? OPROSSO_SERVICE_NAME : Vars.OPROSSO_SERVICE_NAME;
let serviceLink = isFallback(Vars.OPROSSO_SERVICE_LINK) ? OPROSSO_SERVICE_LINK : Vars.OPROSSO_SERVICE_LINK;

let showApiDocumentation = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_API_DOCUMENTATION)
    ? OPROSSO_SHOW_API_DOCUMENTATION
    : Vars.OPROSSO_SHOW_API_DOCUMENTATION,
);

let showReactionInAllSurveys = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS)
    ? OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS
    : Vars.OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS,
);

let chromeExtensionId = isFallback(Vars.OPROSSO_CHROME_EXTENSION_ID)
  ? OPROSSO_CHROME_EXTENSION_ID
  : Vars.OPROSSO_CHROME_EXTENSION_ID;

let showImportByListButtonInMailing = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING)
    ? OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING
    : Vars.OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING,
);

let showVariableInQTitle = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_VARIABLE_IN_Q_TITLE)
    ? OPROSSO_SHOW_VARIABLE_IN_Q_TITLE
    : Vars.OPROSSO_SHOW_VARIABLE_IN_Q_TITLE,
);

let showChannelLinkEditing = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_CHANNEL_LINK_EDITING)
    ? OPROSSO_SHOW_CHANNEL_LINK_EDITING
    : Vars.OPROSSO_SHOW_CHANNEL_LINK_EDITING,
);

let showUrlShortener = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_URL_SHORTENER) ? OPROSSO_SHOW_URL_SHORTENER : Vars.OPROSSO_SHOW_URL_SHORTENER,
);

let preScrollOpenedQuestionInPanel = toBoolean(
  isFallback(Vars.OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL)
    ? OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL
    : Vars.OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL,
);

let showSPSSExport = toBoolean(
  isFallback(Vars.OPROSSO_SHOW_SPSS_EXPORT) ? OPROSSO_SHOW_SPSS_EXPORT : Vars.OPROSSO_SHOW_SPSS_EXPORT,
);

let supportEmail = isFallback(Vars.OPROSSO_SUPPORT_EMAIL) ? OPROSSO_SUPPORT_EMAIL : Vars.OPROSSO_SUPPORT_EMAIL;

let supportChatBot = isFallback(Vars.OPROSSO_SUPPORT_CHAT_BOT)
  ? OPROSSO_SUPPORT_CHAT_BOT
  : Vars.OPROSSO_SUPPORT_CHAT_BOT;

const prod = PROD;

type AppVarsConfig = Partial<ImportMetaEnv>;

export async function initApplicationsVars() {
  return await fetch("/app.config.json?v=1", {})
    .then((res) => res.json() as Promise<AppVarsConfig>)
    .then((config) => {
      // eslint-disable-next-line no-console
      console.group("File app.config.json is resolved. Environment variables will be taken out of him.");
      // eslint-disable-next-line no-console
      console.log(config);
      // eslint-disable-next-line no-console
      console.groupEnd();
      return config;
    })
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .then((config) => {
      // eslint-disable-next-line no-console
      console.log("config", config);
      console.warn("Environment variables must have the prefix 'OPROSSO_' !");

      // override env from app.config.json
      // he has higher priority
      if (config?.OPROSSO_API_BASE_URL !== undefined) {
        apiUrl = config.OPROSSO_API_BASE_URL;
      }

      if (config?.OPROSSO_CHATRA !== undefined) {
        chatra = config.OPROSSO_CHATRA;
      }
      if (config?.OPROSSO_PANEL_URL !== undefined) {
        panelUrl = config.OPROSSO_PANEL_URL;
      }

      if (config?.OPROSSO_HELP_LINK !== undefined) {
        helpLink = config.OPROSSO_HELP_LINK;
      }

      if (config?.OPROSSO_SERVICE_LINK !== undefined) {
        serviceLink = config.OPROSSO_SERVICE_LINK;
      }

      if (config?.OPROSSO_SERVICE_NAME !== undefined) {
        serviceName = config.OPROSSO_SERVICE_NAME;
      }

      if (config?.OPROSSO_CAN_CREATE_TRIGGER_SURVEY !== undefined) {
        canCreateTriggerSurvey = config.OPROSSO_CAN_CREATE_TRIGGER_SURVEY;
      }

      if (config?.OPROSSO_SHOW_API_DOCUMENTATION !== undefined) {
        showApiDocumentation = config.OPROSSO_SHOW_API_DOCUMENTATION;
      }

      if (config?.OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS !== undefined) {
        showReactionInAllSurveys = config.OPROSSO_SHOW_REACTION_IN_ALL_SURVEYS;
      }

      if (config?.OPROSSO_CHROME_EXTENSION_ID !== undefined) {
        chromeExtensionId = config.OPROSSO_CHROME_EXTENSION_ID;
      }

      if (config?.OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING !== undefined) {
        showImportByListButtonInMailing = config.OPROSSO_SHOW_IMPORT_BY_LIST_BUTTON_IN_MAILING;
      }

      if (config?.OPROSSO_SHOW_VARIABLE_IN_Q_TITLE !== undefined) {
        showVariableInQTitle = config.OPROSSO_SHOW_VARIABLE_IN_Q_TITLE;
      }

      if (config?.OPROSSO_SHOW_CHANNEL_LINK_EDITING !== undefined) {
        showChannelLinkEditing = config.OPROSSO_SHOW_CHANNEL_LINK_EDITING;
      }

      if (config?.OPROSSO_SHOW_URL_SHORTENER !== undefined) {
        showUrlShortener = config.OPROSSO_SHOW_URL_SHORTENER;
      }

      if (config?.OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL !== undefined) {
        preScrollOpenedQuestionInPanel = config.OPROSSO_PRE_SCROLL_OPENED_QUESTION_IN_PANEL;
      }

      if (config?.OPROSSO_SHOW_SPSS_EXPORT !== undefined) {
        showSPSSExport = config.OPROSSO_SHOW_SPSS_EXPORT;
      }

      if (config?.OPROSSO_SUPPORT_EMAIL !== undefined) {
        supportEmail = config.OPROSSO_SUPPORT_EMAIL;
      }

      if (config?.OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT !== undefined) {
        showNewChatByDefault = config.OPROSSO_SHOW_NEW_CHAT_BY_DEFAULT;
      }

      if (config?.OPROSSO_SHOW_NEW_CHAT_ONLY_FOR !== undefined) {
        showNewChatOnlyFor = toArray(config.OPROSSO_SHOW_NEW_CHAT_ONLY_FOR);
      }

      if (config?.OPROSSO_SUPPORT_CHAT_BOT !== undefined) {
        supportChatBot = config.OPROSSO_SUPPORT_CHAT_BOT;
      }

      return config;
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.info("File app.config.json not founded. Environment variables will be taken from env.");
    })
    .finally(() => {
      logApplicationVars();
    });
}

function logApplicationVars(): void {
  ApplicationVars.parse({
    ...getApplicationVars(),
    panelUrl: panelUrl.toString(),
  });
  // eslint-disable-next-line no-console
  console.log("ApplicationVars:", getApplicationVars());
}

export function getApplicationVars(): TypeOf<typeof ApplicationVars> {
  return {
    apiUrl,
    canCreateTriggerSurvey,
    chatra,
    chromeExtensionId,
    helpLink,
    panelUrl: window.location.origin + panelUrl,
    preScrollOpenedQuestionInPanel,
    prod,
    serviceLink,
    serviceName,
    showApiDocumentation,
    showChannelLinkEditing,
    showImportByListButtonInMailing,
    showNewChatByDefault,
    showNewChatOnlyFor,
    showReactionInAllSurveys,
    showSPSSExport,
    showUrlShortener,
    showVariableInQTitle,
    supportChatBot,
    supportEmail,
  };
}
